import React, { useRef, useEffect } from 'react'
// import { getAnalytics, logEvent } from "firebase/analytics";
import * as LogSnag from '../LogSnag';

const Meeting = () => {
    const elementRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
          const element = elementRef.current;
          const trigger = window.scrollY + window.innerHeight;
          const elementTop = element.offsetTop;
  
          if (trigger > elementTop) {
            // Top of the element is within the viewport
            element.classList.add('animate-fade-in-fast');
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      async function handleBookCallClick() {
        await LogSnag.createLogSnagEvent(LogSnag.bookCallEvent, "Schedule Meeting ☎️")
      }

  return (
    <div className='bg-sl-green'>
        <div className="mx-auto max-w-4xl py-6 px-6 text-center">
            <img 
                ref={elementRef} 
                className='mx-auto h-20 mb-6'
                src="https://firebasestorage.googleapis.com/v0/b/softdfw.appspot.com/o/benefits_img%2Fchat_bubble.png?alt=media&token=a8493fe9-42a2-40ef-ab2e-63f64b56d35d" 
            />
            <h1 className='text-dark-900 font-cartridge text-4xl font-bold mb-4'>
                Let's meet!
            </h1>
            <p className='text-dark-900 text-lg font-semibold'>
                Sure, we can book a video call, but we are not some remote company around the world. <br />
                <br />
                We believe that building strong, collaborative relationships with our clients is key to delivering successful software projects, 
                and we're committed to going the extra mile to ensure that you're completely satisfied with our work. 
                The coffee's on us! 
            </p>
            <a
                onClick={handleBookCallClick} href="https://cal.com/softlocal/30min" target="_blank"
                className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-sl-dark px-5 py-3 font-cartridge font-regular text-sl-green hover:bg-white hover:text-sl-dark sm:w-auto"
            >
                Schedule a meeting!
            </a>

            {/* <button onClick={activateLasers}>
              Activate Lasers
            </button> */}
        </div>
    </div>
  )
}

export default Meeting