import React from 'react'

const navigation = [
    { name: 'Our Work', href: '#portfolio' },
    { name: 'Process', href: '#process' },
    { name: 'Pricing', href: '#pricing' },
  ]

const Navbar = () => {
  return (
    <header className="bg-sl-dark">
    <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Top">
      <div className="flex w-full items-center justify-between border-b border-indigo-500 py-6 lg:border-none">
        <div className="flex items-center">
          <a href="/">
            <span className="sr-only">Your Company</span>
            <img className="h-10 w-auto" src="/images/softlocal_logo.png" alt="" />
          </a>
          <div className="ml-10 hidden space-x-8 lg:block">
            {navigation.map((link) => (
              <a key={link.name} href={link.href} className="font-cartridge font-medium text-white hover:text-sl-green">
                {link.name}
              </a>
            ))}
          </div>
        </div>
        <div className="ml-10 space-x-4">
          <a
            href="#pricing"
            className="inline-block rounded-md border border-transparent bg-sl-green py-2 px-4 font-cartridge font-bold text-sl-dark hover:bg-opacity-75"
          >
            Start now!
          </a>
        </div>
      </div>
      <div className="flex flex-wrap justify-center space-x-6 py-4 lg:hidden">
        {navigation.map((link) => (
          <a key={link.name} href={link.href} className="font-cartridge font-medium text-white hover:text-indigo-50">
            {link.name}
          </a>
        ))}
      </div>
    </nav>
  </header>
  )
}

export default Navbar