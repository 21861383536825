import React, { useRef, useEffect } from 'react'

const SimpleCTA = (props) => {
    const elementRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
          const element = elementRef.current;
          const trigger = window.scrollY + window.innerHeight;
          const elementTop = element.offsetTop;
  
          if (trigger > elementTop) {
            // Top of the element is within the viewport
            element.classList.add('animate-fade-in-fast');
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <div className={props.bgClass}>
    <div className="mx-auto max-w-2xl py-16 px-6 text-center sm:py-20 lg:py-12 lg:px-8">
      <h2 
        ref={elementRef} 
        className="text-3xl font-cartridge font-bold tracking-tight text-white sm:text-4xl"
      >
        <span className="block">{props.title}</span>
        <span className="block">{props.subtitle}</span>
      </h2>
      <p className="mt-4 text-lg leading-6 text-indigo-200">
        {props.body}
      </p>
      {props.buttonEnabled &&
      <a
        href="#pricing"
        className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-sl-green px-5 py-3 font-cartridge font-bold text-sl-dark hover:bg-indigo-50 sm:w-auto"
      >
        Sign up today
      </a>
      }

    </div>
  </div>
  )
}

export default SimpleCTA