import React from 'react'

const Skyline = () => {
  return (
    <div className='bg-nyc-lg h-686 mt-05 flex justify-center items-center bg-cover bg-center' >

    </div>
  )
}

export default Skyline