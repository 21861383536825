import React, { useRef, useEffect } from 'react'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

const easyJet = [
  {
    name: 'Mobile App.',
    description:
      'The platform connects customers with private aircraft operators and brokers to book private flights. Customers search for private flights by entering their desired departure and arrival locations, date, time, and number of passengers.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Marketplace.',
    description: 'The app displays a list of available aircraft options, including their specifications, photos, and pricing. Customers select the aircraft of their choice and book their flight directly through the app. They can choose to pay for the flight upfront or make a deposit and pay the remainder later.',
    icon: LockClosedIcon,
  },
  {
    name: 'Payment processing.',
    description: 'The app processes the payment and handles any necessary transactions between the customer and the aircraft operator or broker.',
    icon: ServerIcon,
  },
]

const pagoHoy = [
  {
    name: 'Mobile App.',
    description:
      'This app enables users to create and receive payment links that are shared through text messages and it allows to get paid through various payment methods such as credit or debit cards.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Web App.',
    description: 'To create a payment, users can enter the amount and email to send a request to the payer. The payer receives the payment request and approves it by selecting the payment method and entering the necessary information.',
    icon: LockClosedIcon,
  },
  {
    name: 'Track and Confim.',
    description: 'Once the payer has completed the transaction, the app processes the payment by verifying the payment details, authorizing the transaction, and transferring the funds. Both parties can track the status of the payment through the app and receive notifications when the payment is completed.',
    icon: ServerIcon,
  },
]

const projectType = [easyJet, pagoHoy]
const projectDescription = [
{name: "EasyJet", title: "Private Flights Marketplace", description: "We delivered a marketplace for private flights where people can go in and buy empty seats on luxury jets."}, 
{name: "PagoHoy", title: "Payments Platform", description: "With a mobile App, a user can create a payment link that can be shared to anyone in the world and receive a payment."}
]

const DeviceLeft = (props) => {
    // trigger an animation on scroll 
    const elementRef = useRef(null);
    const elementXSRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
          const element = elementRef.current;
          const elementXS = elementXSRef.current;

          const trigger = window.scrollY + window.innerHeight;
          const elementTop = element.offsetTop;
          const elementXSTop = elementXS.offsetTop;
  
          if (trigger > elementTop) {
            // Top of the element is within the viewport
            element.classList.add('animate-fade-right');
          }
          if (trigger > elementXSTop) {
            elementXS.classList.add('animate-fade-right');
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <div id="design">




        <div className="overflow-hidden bg-white py-8 sm:py-32">
            <div className="mx-auto max-w-8xl md:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">

                    <div className="xs:hidden lg:block sm:px-6 lg:px-2">
                        <img
                            ref={elementRef}
                            className="object-contain h-686 xs:mx-auto drop-shadow-2xl"
                            src={props.mockup}
                        />
                    </div>

                    <div className="px-6 md:px-0 lg:pt-4 lg:pr-4">
                        <div className="mx-auto max-w-2xl lg:mx-0 ">
                        <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">{projectDescription[props.projectType].name}</h2>
                        <p className="mt-2 text-3xl font-cartridge font-bold tracking-tight text-gray-900 sm:text-4xl">{projectDescription[props.projectType].title}</p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                        {projectDescription[props.projectType].description}
                        </p>
                        <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            {projectType[props.projectType].map((feature) => (
                            <div key={feature.name} className="relative pl-9">
                                <dt className="inline font-semibold text-gray-900">
                                <feature.icon className="absolute top-1 left-1 h-5 w-5 text-dark-900" aria-hidden="true" />
                                {feature.name}
                                </dt>{' '}
                                <dd className="inline">{feature.description}</dd>
                            </div>
                            ))}
                        </dl>
                        </div>
                    </div>


                    <div className="xs:block lg:hidden xs:mt-05 xs:px-1 sm:px-6 lg:px-2">
                        <img
                            ref={elementXSRef}
                            className="object-contain h-686 xs:mx-auto drop-shadow-2xl"
                            src={props.mockup}
                        />
                    </div>
                </div>
            </div>
        </div>



    </div>
  )
}

export default DeviceLeft