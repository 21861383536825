import './App.css';

import Home from './pages/Home';
import SkolaAndroidDataPolicy from './pages/SkolaAndroidDataPolicy';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAzcrwXb8HDYYap53ACfZJcpdfu7pNX4zM",
  authDomain: "softdfw.firebaseapp.com",
  projectId: "softdfw",
  storageBucket: "softdfw.appspot.com",
  messagingSenderId: "1092781112047",
  appId: "1:1092781112047:web:6f11fa6955b3ce94a2391a",
  measurementId: "G-TE1JKHXMYB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/skolaAndroidDataPolicy' element={<SkolaAndroidDataPolicy />} />
          </Routes>
        <Footer />
      </Router>

    </div>
  );
}

export default App;
