import React, { useRef, useEffect } from 'react'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'


const iter = [
    {
      name: 'Mobile and Web App.',
      description:
        'The apps enable passengers to quickly and easily connect with an available driver. Passengers enter their desired pickup location, destination, and select the type of ride (economy, luxury).',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Real-time map data.',
      description: 'The app matches the passenger with a nearby driver who meets the requirements of the requested ride. The passenger can track the car location, ETA, and real-time route on the map.',
      icon: LockClosedIcon,
    },
    {
      name: 'Payments.',
      description: "The passenger's payment is automatically processed at the end of the ride, and they receive a receipt.",
      icon: ServerIcon,
    },
  ]

  const vintageSports = [
    {
      name: 'Mobile App.',
      description:
        'Users can browse through various categories of videos or search for specific titles or keywords. Once the user selects a video, the app starts streaming it. The video can be played in full-screen mode, and users can pause, rewind, or fast-forward as needed.',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Content Recommendation.',
      description: "This feature offers personalized recommendations based on the user's viewing history, preferences, or other factors.",
      icon: LockClosedIcon,
    },
    {
      name: 'Subscription Management.',
      description: 'The app requires a subscription to access the content, users can manage their subscription, including payment details and cancellation.',
      icon: ServerIcon,
    },
  ]

const projectType = [iter, vintageSports]
const projectDescription = [
  {name: "Iter Mobile", title: "Ride-Sharing App", description: "We implemented a complete ride-sharing system for a taxi company. This project included the development of 5 different mobile apps and a web-based management app."}, 
  {name: "VintageSports", title: "Video Streaming App", description: "Watch past collage football games through this streaming platfom we delivered."}
]

const Device = (props) => {
  // trigger an animation on scroll 
  const elementRef = useRef(null);
  useEffect(() => {
      const handleScroll = () => {
        const element = elementRef.current;
        const trigger = window.scrollY + window.innerHeight;
        const elementTop = element.offsetTop;

        if (trigger > elementTop) {
          // Top of the element is within the viewport
          element.classList.add('animate-fade-left');
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (
    <div>
    <div id="portfolio" className="overflow-hidden bg-white py-8 sm:py-32">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">

          <div className="px-6 md:px-0 lg:pt-4 lg:pr-4">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">{projectDescription[props.projectType].name}</h2>
              <p className="mt-2 text-3xl font-cartridge font-bold tracking-tight text-gray-900 sm:text-4xl">{projectDescription[props.projectType].title}</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {projectDescription[props.projectType].description}
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {projectType[props.projectType].map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute top-1 left-1 h-5 w-5 text-dark-900" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>


          <div className="xs:px-1 xs:mt-05 sm:px-6 lg:px-2">
            <img 
                ref={elementRef}
                className="object-contain h-686 xs:mx-auto drop-shadow-2xl"
                src={props.mockup}
            />
          </div>


        </div>
      </div>
    </div>
    </div>
  )
}

export default Device