import React from 'react'
import Hero from '../components/Hero'
import SimpleCTA from '../components/SimpleCTA'
import Device from '../components/Device'
import DeviceLeft from '../components/DeviceLeft'
import Pricing from '../components/Pricing'
import SimpleCopy from '../components/SimpleCopy'
import Contact from '../components/Contact'
import Process from '../components/Process'
import Benefits from '../components/Benefits'
import FAQ from '../components/FAQ'
import Meeting from '../components/Meeting'
import Skyline from '../components/Skyline'

const Home = () => {
  return (
    <div>
        <Hero />
        <SimpleCTA bgClass={"bg-sl-dark"} title={"Look at what we've worked on"} subtitle={"10+ years of experience."} body={"We've worked on everything from logos to full software systems."} buttonEnabled={true} />
        <Device projectType={0} mockup={"/images/iter_Stacked.png"} />
        <DeviceLeft projectType={0} mockup={"/images/EZJet_Stacked.png"} />
        <Device projectType={1} mockup={"/images/Nieu_VintageSports.png"} />
        <DeviceLeft projectType={1} mockup={"/images/PagoHoy.png"} />
        <Pricing />
        <SimpleCopy />
        <Contact />
        <SimpleCTA bgClass={"bg-dark-900"} title={"Our Process"} subtitle={"Every project is carefully executed"} buttonEnabled={false} />
        <Process />
        <SimpleCTA bgClass={"bg-dark-900"} title={"$2.2K per week."} subtitle={"All the design and code you need."} body={"Software development can be hard. We make the process simple for you."} buttonEnabled={true} />
        <Benefits />
        <SimpleCTA bgClass={"bg-gradient-to-r from-sl-green to-dark-900"} title={"We'd love to work with you!"}  buttonEnabled={true}/>
        <FAQ />
        <Meeting />
        <Skyline />
    </div>
  )
}

export default Home