import React from 'react'

const faqs = [
    {
      id: 1,
      question: "What is a subscription-based software development agency?",
      answer:
        "A subscription-based software development agency is a company that offers ongoing software development services to clients on a subscription basis. Rather than paying for individual development projects as they arise, clients pay a monthly or yearly fee to have access to a dedicated team of developers who work on their software projects over time.",
    },
    {
        id: 2,
        question: "What are the benefits of a subscription-based model?",
        answer:
          "Subscription-based models offer a range of benefits for businesses, including predictable costs, ongoing support, and customized solutions. You can budget for software development costs without worrying about unexpected expenses, and have access to ongoing support and maintenance. Our customized solutions can be fine-tuned over time to meet changing business needs.",
      },
      {
        id: 3,
        question: "What development methodologies do you use?",
        answer:
          "We use agile development methodologies, which prioritize flexibility and rapid iteration. Agile approaches allow for ongoing development work and iterations, making it possible to meet changing business needs.",
      },
      {
        id: 4,
        question: "How do you ensure quality?",
        answer:
          "Quality is the top priority. We ensure software quality by using automated testing tools, performing regular code reviews, and conducting user acceptance testing.",
      },
      {
        id: 5,
        question: "Can I cancel my subscription at any time?",
        answer:
          "Yes. You can cancel your subscription at any time. No questions asked.",
      },
      {
        id: 6,
        question: "What happens to my project if I cancel?",
        answer:
          "All assets and data developed at the time of the active subscriptions belong to you. Ownership and retention is 100% yours.",
      },
  ]

const FAQ = () => {
  return (
    <div>
        <div id="faq" className="bg-paleGrey">
        <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-2xl font-cartridge font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
            <p className="mt-6 text-base leading-7 text-gray-600">
                Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
                <a href="mailto:salvador@softlocal.io" className="font-semibold text-sdfwBlue-3 hover:text-sdfwBlue-3">
                sending us an email
                </a>{' '}
                and we’ll get back to you as soon as we can.
            </p>
            </div>
            <div className="mt-20">

            <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
                {faqs.map((faq) => (
                <div key={faq.id} className="bg-white rounded-2xl drop-shadow-lg px-4 pt-4 pb-2">
                    <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                </div>
                ))}
            </dl>

            </div>
        </div>
        </div>
    </div>
  )
}

export default FAQ