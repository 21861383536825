import React, { useRef, useEffect }  from 'react'
// import { getAnalytics, logEvent } from "firebase/analytics";
import * as LogSnag from '../LogSnag';

const navigation = {
  solutions: [
    { name: 'Software', href: '#' },
    { name: 'Mobile Apps', href: '#portfolio' },
    { name: 'Design', href: '#design' },
  ],
  support: [
    { name: 'Pricing', href: '#pricing' },
    { name: 'Process', href: '#process' },
    { name: 'FAQ', href: '#faq' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Book a call', href: 'https://cal.com/softlocal/30min' },
  ],
  legal: [
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [

  ],
}

const Footer = () => {
    let didAnalyticsTrigger = false;
    const elementRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
          const element = elementRef.current;
          const trigger = window.scrollY + window.innerHeight;
          const elementTop = element.offsetTop;
  
          if (trigger > elementTop) {
            // Top of the element is within the viewport
            element.classList.add('animate-fade-in-fast');
            triggerEvents()
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  
      function triggerEvents() {
        if (!didAnalyticsTrigger) {
          didAnalyticsTrigger = true
          // LogSnag
          creatLogSnagEvent()
          // Google Analytics
        //   const analytics = getAnalytics();
        //   logEvent(analytics, 'footer_visited');
  
        }
      }
  
      async function creatLogSnagEvent() {
        await LogSnag.createLogSnagEvent(LogSnag.footerViewEvent, "🫡Footer")
      }
  
  
    return (
      <div>
          <footer className="bg-gray-900" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
              Footer
            </h2>
            <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-8">
              <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                <img
                  ref={elementRef} 
                  className="h-10"
                  src="/images/softlocal_logo.png"
                  alt="Company name"
                />
                <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                  <div className="md:grid md:grid-cols-2 md:gap-8">
  
  
                    <div>
                      <h3 className="text-sm font-semibold leading-6 text-white">Solutions</h3>
                      <ul role="list" className="mt-6 space-y-4">
                        {navigation.solutions.map((item) => (
                          <li key={item.name}>
                            <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
  
  
                    <div className="mt-10 md:mt-0">
                      <h3 className="text-sm font-semibold leading-6 text-white">Service</h3>
                      <ul role="list" className="mt-6 space-y-4">
                        {navigation.support.map((item) => (
                          <li key={item.name}>
                            <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
  
                  <div className="md:grid md:grid-cols-2 md:gap-8">
  
  
                    <div>
                      <h3 className="text-sm font-semibold leading-6 text-white">Company</h3>
                      <ul role="list" className="mt-6 space-y-4">
                        {navigation.company.map((item) => (
                          <li key={item.name}>
                            <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
  
  
                    <div className="mt-10 md:mt-0">
                      <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
                      <ul role="list" className="mt-6 space-y-4">
                        {navigation.legal.map((item) => (
                          <li key={item.name}>
                            <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
  
  
                  </div>
                </div>
              </div>
              <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">Subscribe to our newsletter</h3>
                  <p className="mt-2 text-sm leading-6 text-gray-300">
                    The latest news, articles, and resources, sent to your inbox weekly.
                  </p>
                </div>
                <form className="mt-6 sm:flex sm:max-w-md lg:mt-0">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email-address"
                    id="email-address"
                    autoComplete="email"
                    required
                    className="w-full min-w-0 appearance-none rounded-md border-white/10 bg-gray-400/10 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base leading-7 text-white placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:w-56 sm:text-sm sm:leading-6"
                    placeholder="Enter your email"
                  />
                  <div className="mt-4 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
                    <button
                      type="submit"
                      className="flex w-full items-center justify-center rounded-md bg-sl-green py-1.5 px-3 text-base font-semibold leading-7 text-sl-dark shadow-sm hover:bg-sdfwBlue-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 sm:text-sm sm:leading-6"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
              <div className="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
                <div className="flex space-x-6 md:order-2">
                  {navigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
                <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
                  &copy; 2023 SoftLocal, LLC. All rights reserved.
                </p>
              </div>
            </div>
          </footer>
      </div>
    )
}

export default Footer