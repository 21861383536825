// Channel Names
export const softlocalChannel = "softlocal";
// Event Names
export const pricingViewEvent = "softLocal Pricing"
export const footerViewEvent = "softLocal Footer"
export const contactEvent = "softLocal Contact"
export const bookCallEvent = "softLocal BookCall"

// Functions
export async function createLogSnagEvent(eventName, description) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer b2aebb1fa7362d97a80b3dea820d36a5");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "project": "pagohoy",
        "channel": softlocalChannel,
        "event": eventName,
        "notify": true,
        "description": description,
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch("https://api.logsnag.com/v1/log", requestOptions)
        .then(response => response.text())
        .then(result => console.log("Contact/Success"))
        .catch(error => console.log('error', error));
}