import React, { useRef, useEffect } from 'react'

const SimpleCopy = () => {
    const elementRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
          const element = elementRef.current;
          const trigger = window.scrollY + window.innerHeight;
          const elementTop = element.offsetTop;
  
          if (trigger > elementTop) {
            // Top of the element is within the viewport
            element.classList.add('animate-fade-in-fast');
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <div>
      <div className='bg-bottom-left-side-blue bg-no-repeat bg-left-bottom mb-0'>
        <div className='bg-bottom-right-side-pink bg-no-repeat bg-right-bottom mb-0'>
        <div className="mx-auto max-w-4xl pt-2 pb-4 lg:px-6 md:px-4 xs:pb-4 xs:px-20 text-center">
            <img 
                ref={elementRef} 
                className='mx-auto h-20 mb-6'
                src="https://firebasestorage.googleapis.com/v0/b/softdfw.appspot.com/o/benefits_img%2Fmountain.png?alt=media&token=b59c98b6-6172-4114-a103-428dfa43edb2" 
            />
            <h1 className='text-dark-900 font-cartridge text-4xl font-bold mb-4'>
                So why a subscription?
            </h1>
            <p className='text-lg xs:pb-4'>
                We know how frustrating extra costs that can be. <br />
                Typically, software agencies will quote you for each individual item you want to build, and before you know it, the bill can quickly spiral out of control. <br /><br />
                However, here at SoftLocal, we believe that building quality software should not have to be a costly and stressful endeavor. 
                That's why we offer a monthly subscription that grants you unlimited requests for everything you need, without any extra charges. <br /><br />
                This approach not only saves you money, but it also provides both you and us with greater certainty on how the project is progressing and how much it will ultimately cost. 
            </p>
            <br /><br />
            <img />
        </div>
        </div>
        </div>
    </div>
  )
}

export default SimpleCopy