import React, { useRef, useEffect } from 'react'

const people = [
    {
      name: 'Plan',
      role: 'We plan together the design, coding and deployment of your project. We set objectives, identify potential roadblocks, and allocate resources effectively.',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/softdfw.appspot.com/o/benefits_img%2Fjournal.png?alt=media&token=f0683d32-e200-46b7-8640-ae7a1a490d4d',
    },
    {
        name: 'Track',
        role: 'With your dedicated work items board you can monitor your progress. You can identify what is working and what needs improvement, and adjust the implementation strategy accordingly.',
        imageUrl:
          'https://firebasestorage.googleapis.com/v0/b/softdfw.appspot.com/o/benefits_img%2Fgoal.png?alt=media&token=19e9976d-2f20-4b97-96b6-b6499dd8dbc3',
      },
      {
        name: 'Quality',
        role: 'Our focus on quality throughout the development process helps to ensure that the software meets the required standards and is delivered on time and within budget.',
        imageUrl:
          'https://firebasestorage.googleapis.com/v0/b/softdfw.appspot.com/o/benefits_img%2Ftarget_2.png?alt=media&token=14bf2116-63ec-441d-9a59-2c9940b9933c',
      },
      {
        name: 'Delight',
        role: 'A customer-centric approach and a commitment to understanding your needs and preferences, We go above and beyond your expectations to create a positive and memorable experience throughout the implementation.',
        imageUrl:
          'https://firebasestorage.googleapis.com/v0/b/softdfw.appspot.com/o/benefits_img%2Fstars.png?alt=media&token=f2f1085b-4a5d-4f50-bcf3-057cdf2ac4f1',
      },
      {
          name: 'Launch',
          role: 'By launching a minimum viable product, you can focus on the core features of your product and deliver it to the market quickly. This approach allows you to test your product market fit and gather feedback from early adopters.',
          imageUrl:
            'https://firebasestorage.googleapis.com/v0/b/softdfw.appspot.com/o/benefits_img%2Fspace_shuttle.png?alt=media&token=ce6e1592-6b11-4edd-82f9-775fbf889bde',
        },
        {
          name: 'Save',
          role: 'We offer a monthly membership with unlimited requests for a predictable cost, saving you money and providing certainty on progress. Membership is flexible and can be canceled anytime.',
          imageUrl:
            'https://firebasestorage.googleapis.com/v0/b/softdfw.appspot.com/o/benefits_img%2Fmoney_bag.png?alt=media&token=af08993b-7b41-4b46-90b8-4de0c5287040',
        },
      
  ]

const Benefits = () => {
  const iconRefs = useRef([]);

  useEffect(() => {

      const handleScroll = () => {
        const trigger = window.scrollY + window.innerHeight;

        for (let i = 0; i < iconRefs.current.length; i++) {
          const currentIcon = iconRefs.current[i]
          const elementTop = currentIcon.offsetTop;
          if (trigger > elementTop) {
            currentIcon.classList.add('animate-fade-in-two')
          }
        }
      }

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (
    <div>
      <div className='bg-bottom-right-pink bg-no-repeat bg-right-bottom mb-0'>
        <div className='bg-top-left-blue bg-no-repeat bg-left-top flex justify-center'>
          <div className="bg-clear pt-16 pb-16">
              <div className="mx-auto max-w-7xl px-6 text-center lg:px-20">
                  <div className="mx-auto max-w-2xl">
                  <h2 className="text-3xl font-cartridge font-bold tracking-tight text-gray-900 sm:text-4xl xs:px-20">Uncomplicated process. Great results.</h2>
                  <p className="mt-4 text-lg leading-8 text-gray-600 xs:px-10">
                      We are passionate about what we do.
                  </p>
                  </div>
                  <ul
                  role="list"
                  className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
                  >
                  {people.map((person, index) => (
                      <li key={person.name}>
                        <img ref={(el) => (iconRefs.current[index] = el)}  className="mx-auto h-40 w-40 rounded-full" src={person.imageUrl} alt="" />
                        <h3 className="mt-6 pb-4 text-3xl font-cartridge font-bold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                        <p className="text-sm px-6 leading-6 text-gray-600">{person.role}</p>
                      </li>
                  ))}
                  </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Benefits