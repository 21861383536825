import React, { useRef, useEffect } from 'react'
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'

const features = [
    {
      name: 'Design',
      description:
        'UI/UX ideation. See how your app or website is going to look first before we start coding it. Unlimited changes.',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Code',
      description:
        'Once the design is approved the code implementation starts. We show you every screen before moving on to the next.',
      icon: LockClosedIcon,
    },
    {
      name: 'Test',
      description:
        'Quality is the most important feature of your product. We make sure the code implementation is 100% free of defects.',
      icon: ArrowPathIcon,
    },
    {
      name: 'Deploy',
      description:
        'Finally, your product goes live. We deploy your app to the Apple and Google app stores.',
      icon: FingerPrintIcon,
    },
  ]

const Features = () => {
    // trigger an animation on scroll 
    const elementRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
          const element = elementRef.current;
          const trigger = window.scrollY + window.innerHeight;
          const elementTop = element.offsetTop;
  
          if (trigger > elementTop) {
            // Top of the element is within the viewport
            element.classList.add('animate-fade-in-two');
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <div id="process" className="bg-paleGrey lg:py-20 xs:py-8">
        <div ref={elementRef}  className="bg-white rounded-2xl drop-shadow-xl lg:mx-40 lg:py-12 sm:py-8 xs:mx-6 xs:py-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-lg font-semibold leading-8 tracking-tight text-sdfwBlue-2">Deploy your idea faster</h2>
            <p className="mt-2 text-3xl font-cartridge font-bold tracking-tight text-gray-900 sm:text-4xl">
                From step one to done
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
                You are involved in every step of the way. From design to go live, you have complete control of the implementation.
            </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900 xs:text-left">
                    <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-sl-green">
                        <feature.icon className="h-6 w-6 text-sl-dark" aria-hidden="true" />
                    </div>
                    {feature.name}
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600 xs:text-left xs:mt-4">{feature.description}</dd>
                </div>
                ))}
            </dl>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Features